import React, { useEffect, useState, useContext } from 'react';

import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import { fetchData, postData } from '../../utils/http';
import BasicClassCard from '../basic-class-card/BasicClassCard';
import { LoaderContext } from '../../utils/contexts';

import './uploadClasses.scss';
import { isAdmin } from '../../utils/auth';
import MyInput from '../my-input/MyInput';
import CreateNewClass from '../create-new-class/CreateNewClass';
import LinearProgressWithLabel from '../Linear-progress-with-label/LinearProgressWithLabel';
import { useOutletContext } from 'react-router-dom';

async function uploadFile2({ data, file }, onUploadProgress) {
  const format = file ? file.name.split('.')[1] : null;
  const formData = new FormData();
  data.duration = file ? await getDuration(file, data.media_type) : null;
  formData.append('data', JSON.stringify(data));
  if (file) {
    formData.append('file', file, data.pickedClass + '.' + format);
  }
  return postData(
    'class/upload-class-file',
    formData,
    true,
    onUploadProgress // eslint-disable-line no-undef
  );
}

async function getDuration(file, mediaType) {
  const url = URL.createObjectURL(file);
  return new Promise((resolve) => {
    const mediaEl = document.createElement(mediaType);
    mediaEl.muted = true;
    mediaEl.src = url; //--> blob URL
    mediaEl.preload = 'metadata';
    mediaEl.onloadedmetadata = function () {
      resolve(mediaEl.duration);
    };
  });
}

function extractYouTubeVideoId(url) {
  let videoId = null;
  const pattern =
    /(?:youtube\.com\/(?:[^/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/; // eslint-disable-line no-useless-escape

  const matches = url.match(pattern);
  if (matches && matches.length > 1) {
    videoId = matches[1];
  }

  return videoId;
}

export default function UploadClasses() {
  const { loaderC, uploadPercentageC } = useContext(LoaderContext);
  const { setLoader } = loaderC;
  const [classes, setClasses] = useState([]);
  const [lessonsToUpload, setLessonsToUpload] = useState([]);
  const [currClass, setCurrClass] = useState({});
  const [classList, setClassList] = useState([]);
  const [filteredClassList, setFilteredClassList] = useState([]);
  const [embed, setEmbed] = useState(false);
  const [oneToThree, setOneToThree] = useState(false);
  const [validErr, setValidErr] = useState(true);
  const [user] = useOutletContext();

  useEffect(() => {
    getEndSetClasses();
  }, [user]);

  useEffect(() => {
    setFilteredClassList(classList);
  }, [classList]);

  useEffect(() => {
    if (currClass.media_type !== undefined && currClass.media_type !== '') {
      fetchData(
        `class/get-classes-list?track=${currClass.track}&classId=${currClass.id}`
      ).then((res) => {
        setClassList(res.data);
      });
    }
  }, [currClass]);

  const getEndSetClasses = () => {
    if (user.id) {
      fetchData(`class/get-classes-by-lecturer?userId=${user.id}`).then(
        (res) => {
          setClasses(res.data);
        }
      );
    }
  };

  function deleteClass(id) {
    const conf = window.confirm('האם אתה בטוח שברצונך למחוק את השיעור?');
    if (conf) {
      postData('class/delete-class', { classId: id, lecId: user?.id }).then(
        (rs) => {
          getEndSetClasses();
        }
      );
    }
  }

  function handleSetCurrClass(cls) {
    setCurrClass({});
    setTimeout(() => {
      setCurrClass(cls);
      setEmbed(cls.from_youtube);
      setOneToThree(cls.one_to_three);
    }, 0);
  }

  function filterClassList(checked) {
    let clsList = [...classList];
    if (checked) {
      clsList = clsList.filter((cl) => !cl.exists);
    }
    setFilteredClassList(clsList);
  }
  const handleChange = (e) => {
    const name = e.target.name;
    // const value = e.target.value;
    const checked = e.target.checked;
    switch (name) {
      case 'embed':
        setEmbed(checked);
        postData('class/update-class', {
          id: currClass.id,
          embed: checked,
          oneToThree,
        }).then((res) => {
          console.log(res);
        });
        break;
      case 'oneToThree':
        setOneToThree(checked);
        postData('class/update-class', {
          id: currClass.id,
          embed,
          oneToThree: checked,
        }).then((res) => {
          console.log(res);
        });
        break;
      case 'filter':
        filterClassList(checked);
        break;
      default:
        break;
    }
  };

  function onPickChange(params, newVal) {
    const lessons = [...lessonsToUpload];
    setLessonsToUpload([{ id: newVal.id, progress: 0 }]);
  }

  function handleInput(e, type) {
    const newFile = e.target.files ? e.target.files[0] : null;
    const name = +e.target.name;
    const formats =
      currClass.media_type === 'audio'
        ? ['mp3', 'ogg', 'm4a', 'mpeg']
        : ['mp4'];
    if (
      type === 'file' &&
      !formats.some((f) => newFile.name.toLowerCase().endsWith(f))
    ) {
      alert(`על הקובץ להיות בפורמט ${formats.join(', ')}`);
      e.target.value = null;
      return;
    }
    const lessons = [...lessonsToUpload];
    const lessonIndex = lessons.findIndex((ls) => ls.id === name);
    if (type === 'file') {
      lessons[lessonIndex].file = newFile;
    } else {
      lessons[lessonIndex].embedId = extractYouTubeVideoId(e.target.value);
    }
    if (!lessons[lessonIndex + 1]) {
      lessons.push({ id: name + 1, progress: 0 });
    }
    setLessonsToUpload(lessons);
  }

  function onUploadProgress(data, index) {
    const lessons = [...lessonsToUpload];
    lessons[index].progress = (data.progress * 100).toFixed();
    setLessonsToUpload(lessons);
  }

  function submitFile() {
    lessonsToUpload.forEach((ls, i) => {
      setLoader(true);
      const lessons = [...lessonsToUpload];
      if (!ls.file && !ls.embedId) return;
      uploadFile2(
        {
          data: {
            pickedClass: ls.id,
            ...currClass,
            mainFolder: user?.main_folder,
            embed,
            embedId: ls.embedId,
            track: currClass.track,
            oneToThree,
          },
          file: ls.file,
        },
        (data) => onUploadProgress(data, i)
      )
        .then((res) => {
          lessons[i].success = '✅';
          setLessonsToUpload(lessons);
          setLoader(false);
        })
        .catch((err) => {
          lessons[i].success = err?.message;
          setLessonsToUpload(lessons);
          setLoader(false);
        });
    });
  }

  return (
    <Box className="upload-classes">
      <Grid container gap={4} pl={5} pb={2} borderBottom={1}>
        <Grid item xs={12}>
          <Typography variant="h4">שיעורים</Typography>
        </Grid>
        {classes.length ? (
          classes.map((cls) => (
            <Grid item xs={2} key={cls.id}>
              <BasicClassCard
                {...cls}
                setCurrClass={handleSetCurrClass}
                deleteClass={deleteClass}
                currId={currClass.id}
              />
            </Grid>
          ))
        ) : (
          <Grid item xs={12}>
            <Typography>עדיין לא יצרת שיעורים</Typography>
          </Grid>
        )}
      </Grid>
      <Box p={5}>
        {Object.hasOwn(currClass, 'id') && (
          <Box className="class-details">
            {isAdmin() && !embed && (
              <Box mt={2}>
                <Button
                  variant="contained"
                  onClick={() =>
                    postData('class/update-lessons-for-class', currClass)
                  }
                >
                  עדכן שיעורים לאחר העלאה מרוכזת
                </Button>
              </Box>
            )}
            {currClass.media_type === 'video' && (
              <Box mt={2}>
                <FormControlLabel
                  control={<Checkbox />}
                  label="הטמעה מיוטיוב"
                  checked={embed}
                  name="embed"
                  onChange={handleChange}
                />
              </Box>
            )}
            {currClass.track === 'one' && (
              <Box mt={2}>
                <FormControlLabel
                  control={<Checkbox />}
                  label="התאם אוטומטית לשלושה פרקים"
                  checked={oneToThree}
                  name="oneToThree"
                  onChange={handleChange}
                />
              </Box>
            )}
            <Box mt={2}>
              <Typography variant="h6">בחר שיעור</Typography>
              <FormControlLabel
                control={<Checkbox />}
                label="סנן שיעורים שהועלו"
                name="filter"
                onChange={handleChange}
              />
              <FormControl fullWidth>
                <Autocomplete
                  disablePortal
                  options={filteredClassList}
                  fullWidth
                  renderInput={(params) => (
                    <TextField
                      fullWidth
                      {...params}
                      placeholder="הקלד לחיפוש מהיר"
                      autoFocus
                    />
                  )}
                  onChange={onPickChange}
                />
              </FormControl>
            </Box>
            <Box mt={2}>
              {lessonsToUpload.length > 0 && (
                <TableContainer component={Paper}>
                  <Table
                    sx={{ minWidth: 650 }}
                    size="small"
                    aria-label="a dense table"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell>מספר שיעור</TableCell>
                        <TableCell>תיאור</TableCell>
                        <TableCell>קובץ</TableCell>
                        <TableCell>התקדמות</TableCell>
                        <TableCell>סטטוס</TableCell>
                        {/* <TableCell align="right">Protein&nbsp;(g)</TableCell> */}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {lessonsToUpload.map((row, i) => (
                        <TableRow
                          key={row.id}
                          sx={{
                            backgroundColor:
                              row.file || row.embedId ? '#e5f7e5' : 'unset',
                            '&:last-child td, &:last-child th': { border: 0 },
                          }}
                        >
                          <TableCell
                            sx={{ width: 10 }}
                            component="th"
                            scope="row"
                          >
                            {row.id}
                          </TableCell>
                          <TableCell sx={{ width: 230 }}>
                            {classList[row.id - 1]?.label}
                          </TableCell>
                          <TableCell sx={{ width: 230 }}>
                            {embed ? (
                              <MyInput
                                type="text"
                                handleChange={(e) => handleInput(e, 'embed')}
                                value={row.embedId}
                                label="קוד הטמעה"
                                setValidErr={setValidErr}
                                validator="text"
                                name={row.id}
                              />
                            ) : (
                              <TextField
                                fullWidth
                                variant="outlined"
                                type="file"
                                name={row.id}
                                label={i === 0 ? 'בחר קובץ' : 'בחר קובץ נוסף?'}
                                InputLabelProps={{ shrink: true }}
                                // disabled={pickedClass === 0}
                                onChange={(e) => handleInput(e, 'file')}
                              />
                            )}
                          </TableCell>
                          <TableCell>
                            <LinearProgressWithLabel value={row.progress} />
                          </TableCell>
                          <TableCell>{row.success}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </Box>
            {/* )} */}
            <Box mt={2}>
              <Button
                variant="contained"
                disabled={
                  !lessonsToUpload[0]?.file && !lessonsToUpload[0]?.embedId
                }
                onClick={submitFile}
              >
                העלה {lessonsToUpload.length - 1} שיעורים
              </Button>
            </Box>
          </Box>
        )}
      </Box>
      <CreateNewClass
        getEndSetClasses={getEndSetClasses}
        lecturerId={user?.id}
      />
    </Box>
  );
}
