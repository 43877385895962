import React, { useState } from 'react';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import './myInput.scss';
import { validEmailRegex, validFullNameRegex } from '../../utils/validation';

function validate (validator, value) {
  const validations = {
    fullname: validFullNameRegex.test(value) ? '' : 'שם מלא נדרש',
    email: validEmailRegex.test(value) ? '' : 'אנא הזן כתובת דוא"ל תקינה',
    password: value.length < 8 ? 'על הסיסמה להכיל לפחות 8 תווים' : '',
    number: value <= 0 ? 'נדרש' : '',
    text: value.length === 0 ? 'נדרש' : '',
  };
  return validations[validator];
}

export default function MyInput(props) {
	const [dirty, setDirty] = useState(false);
	const [error, setError] = useState('');
  const isError = dirty && error !== '';

	function handleChange (e) {
		setDirty(true);
		const err = validate(props.validator, e.target.value);
    setError(err);
    props.setValidErr(err?.length > 0);
		props.handleChange(e); 
	}


	return (
		<Box className="input-box">
			<TextField
				type={props.type || 'text'}
				size="small"
				label={props.label}
				variant="outlined"
				color="primary"
				name={props.name}
				value={props.value}
				onChange={handleChange}
				onBlur={() =>{
					setDirty(true);
					setError(validate(props.validator, props.value));
				}}
        error={isError}
        autoComplete={props.autoComplete}
        fullWidth
			/>
			{isError && (
				<Typography className="input-err">{error}</Typography>
			)}
		</Box>
	);
}